// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    width: 100%;
    color: rgb(226, 226, 226);
    bottom: 0;
    background-color: #333333;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer p {
    transition: opacity 0.7s, padding-left 0.9s, margin-right 0.9s;
    opacity: 0;
}

.footer-center p {
    text-align: center;
    margin-right: 3%;
    opacity: 1;
    transition-delay: 0.3s;
}

.footer-offset p {
    text-align: left;
    padding-left: 10%;
    opacity: 1;
    transition-delay: 0.3s;
}

@media only screen and (max-width: 750px) {
    footer {
        justify-content: flex-start;
        padding: 10px;
        margin-top: 300px;
    }

    footer p {
        opacity: 1;
    }

    .footer-center p, .footer-offset p {
        text-align: center;
        padding-left: 0;
        margin-right: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/pages-style/footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,SAAS;IACT,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,8DAA8D;IAC9D,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI;QACI,2BAA2B;QAC3B,aAAa;QACb,iBAAiB;IACrB;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,kBAAkB;QAClB,eAAe;QACf,eAAe;IACnB;AACJ","sourcesContent":["footer {\n    width: 100%;\n    color: rgb(226, 226, 226);\n    bottom: 0;\n    background-color: #333333;\n    padding: 10px 20px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\nfooter p {\n    transition: opacity 0.7s, padding-left 0.9s, margin-right 0.9s;\n    opacity: 0;\n}\n\n.footer-center p {\n    text-align: center;\n    margin-right: 3%;\n    opacity: 1;\n    transition-delay: 0.3s;\n}\n\n.footer-offset p {\n    text-align: left;\n    padding-left: 10%;\n    opacity: 1;\n    transition-delay: 0.3s;\n}\n\n@media only screen and (max-width: 750px) {\n    footer {\n        justify-content: flex-start;\n        padding: 10px;\n        margin-top: 300px;\n    }\n\n    footer p {\n        opacity: 1;\n    }\n\n    .footer-center p, .footer-offset p {\n        text-align: center;\n        padding-left: 0;\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
